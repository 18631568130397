import './App.css';
import { useState } from 'react'
import { Widget } from '@typeform/embed-react'
import Countdown from 'react-countdown'
import classnames from 'classnames'
import useLocalStorage from "use-local-storage"

const MINUTES = 20
const TIME = MINUTES * 60 * 1000

function App() {
  const [ , setStartTime ] = useState(Date.now())
  const [ isStarted, setIsStarted ] = useState( false )
  const [ isDone, setIsDone ] = useLocalStorage( 'is-done', false )
  const [ doWarn, setDoWarn ] = useState( false )

  const clickStart = () => {
    setStartTime( Date.now() + TIME )
    setIsStarted( true )
  }

  return (
    <div className="App flex flex-col h-screen p-4 pb-0">
      { ! isStarted && ! isDone && <div className="flex-auto flex-col flex justify-center items-center p-4" data-theme="light">
        <h1 className="text-4xl font-bold mb-4">Stackable ReactJS Technical Assessment</h1>
        <p className="mb-8 max-w-4xl">Hello, you have <strong>{ MINUTES } minutes</strong> to answer this test. Be sure that you reach the very end of the assessment and then click the "submit" button <strong>well before the timer runs out</strong>. When the timer hits zero the assessment will close, and if you haven't submitted, it will be considered a fail.</p>
        <button className="btn btn-primary" onClick={ clickStart }>Start Assessment</button>
      </div> }
      { isDone && <div className="flex-auto flex-col flex justify-center items-center p-4" data-theme="light">
        <h1 className="text-4xl font-bold">Technical Assessment Completed!</h1>
        <p className="mb-8">Thank you, we will reach out to you for the next steps of the application process.</p>
      </div> }
      { isStarted && ! isDone && <Widget id="hQNUg08l" className="flex-auto"/> }
      <div className={ classnames( "text-xl font-bold text-center p-4 flex-none text-white", { 'text-error': doWarn } ) }>
        <Countdown
          date={Date.now() + TIME}
          renderer={ ({ hours, minutes, seconds, completed }) => {
            if (! isStarted && ! isDone) {
              return `Timer: ${ MINUTES }:00`
            } else if ( completed || isDone ) {
              // Render a completed state
              return 'Time\'s up!'
            } else {
              if ( minutes < 5 ) {
                setDoWarn( true )
              }
              // Render a countdown
              return <span>
                Timer: { minutes.toString().padStart( 2, '0' ) }:{ seconds.toString().padStart( 2, '0' ) }
                &nbsp;
                { doWarn ? 'Reach the end of the assessment & submit before time runs out' : '' }
              </span>;
            }
          } }
          onComplete={ () => {
            setIsDone( true )
          }}
        />
      </div>
    </div>
  );
}

export default App;
